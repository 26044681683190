<template>
  <div class="user">
    <!-- 面包屑导航 -->
    <el-card>
      <!-- 头 -->
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input placeholder="请输入内容" v-model="getInfo.info">
            <el-button
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </el-col>
        <el-col :span="8"> </el-col>
      </el-row>
      <!-- 表格 -->
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
      >
       <el-table-column width="120px"  prop="id" align="center" label="家庭ID"></el-table-column>
        <el-table-column  prop="family" align="center"  label="家庭名称"></el-table-column> 
        <el-table-column width="120px" align="center" prop="count" label="成员人数"></el-table-column>
        <el-table-column  align="center" prop="status_text" label="状态"></el-table-column>
        <el-table-column  align="center" prop="create_time" label="注册日期"></el-table-column>
        <el-table-column label="操作"  align="center">
          <template slot-scope="scope">
            <!-- <el-button
              size="mini"
              icon="el-icon-edit"
              @click="searchOpen(scope.row)"
            ></el-button>
            <el-button
              size="mini"
              icon="el-icon-delete"
              type="danger"
              @click="searchOpen(scope.row)"
            ></el-button> -->
           <el-tooltip class="item" effect="dark" content="查看家庭详情" placement="top-start">
            <el-button icon="el-icon-search" @click="familyDetail(scope.row.id)" ></el-button>
          </el-tooltip>
           <el-tooltip class="item" effect="dark"  content="查看家庭消费" placement="top-start">
            <el-button icon="iconfont icon-xiaofei01" type="primary" @click="familyCustomer(scope.row.id,scope.row.user_id)" ></el-button>
          </el-tooltip>
           <el-tooltip v-if="scope.row.status==2&&scope.row.unsubscribe_status==0" class="item" effect="dark"  content="家庭审核" placement="top-start">
            <el-button icon="el-icon-edit" type="danger" @click="familyAudit(scope.row.id,scope.row.status_text)" ></el-button>
          </el-tooltip>
          <el-tooltip v-else class="item" effect="dark"  content="家庭审核" placement="top-start">
            <el-button icon="el-icon-edit" type="info" disabled></el-button>
          </el-tooltip>
             <!-- <el-button   type="primary" >查看成员</el-button>
             <el-button  @click="familyDetail(scope.row.id)" type="primary" >查看详情</el-button> -->
              <!-- <el-button  @click="familyCustomer(scope.row.id,scope.row.user_id)" type="primary" >查看消费</el-button>
              <el-button  @click="familyAudit(scope.row.id)" type="primary" >家庭审核</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="pageChange"
        @size-change="sizeChange"
        :page-sizes="[7, 15, 20]"
        layout="total, sizes, prev, pager, next"
        :page-size="this.getInfo.page_num"
        background
        :total="exp.num"
      >
      </el-pagination>
    </el-card>
    <!-- 审核提示框 -->
    <el-dialog
  title="提示"
  :visible.sync="dialogVisible"
  width="30%"
  :before-close="handleClose">
  <span>是否同意开通家庭?</span>
  <span slot="footer" class="dialog-footer">
    <el-button @click="refuse">拒 绝</el-button>
    <el-button type="primary" @click="consent">同 意</el-button>
  </span>
</el-dialog>
  </div>
  
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { postFamilyList,postFamilyAssessor} from "@/api/Family/family.js";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      //审核boolean
      dialogVisible: false,
      //总条数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 15,
      },
      //表格数据
      tableData: [],
      dialogFalimyID:''
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
     //家庭审核
    familyAudit(id,satustext){
       console.log(id,satustext,"家庭审核");
      //  if(satustext=="正常"){
      //     this.$confirm('该正常已审核成功！').then(()=>{
                  
      //     }).catch(()=>{

      //     })
      //     return;
      //  }
       this.dialogFalimyID=id
       this.dialogVisible=true
    },
    //家庭详情
    familyDetail(id){
       console.log(id,"家庭详情");
        this.$router.push({
        path:"/family/detail",
        query:{
          id
        }
      })  
    },
    //家庭消费记录
    familyCustomer(id,userid){
      console.log(id,"家庭消费记录");
       this.$router.push({
        path:"/family/consume",
        query:{
          id
        }
      })      
    },
    //查看家庭成员
    familyMember(id){
      this.$router.push({
        path:"/family/member",
        query:{
          id
        }
      })
    },
    //修改每页数量
    sizeChange(value) {
      this.getInfo.page_num = value;
      var data={
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num
    }
    console.log(data);
    this.tableData=[]
    this.postFamilyList(data);
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value;
      var data={
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num
    }
    console.log(data);
    this.tableData=[]
    this.postFamilyList(data);
    },
    handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      //拒绝开通家庭
      refuse(){
        var that=this;
        var data={
          id:this.dialogFalimyID,
          status:2
         }
          this.$confirm('确认拒绝？')
          .then(_ => {
                   let res=postFamilyAssessor(data);
                res.then((res)=>{
                     if(res.data.code==1){
           this.tableData=[]
                  var data={
              page:this.getInfo.page_code,
              list_num:this.getInfo.page_num
            }
            this.postFamilyList(data);
              this.$message({
              message: res.data.message,
              type: 'success'
            });
         }else{
             this.$message.error(res.data.message);
         }
                }).catch((err)=>{
                    console.log(err);
                })
                 that.dialogVisible=false;
          })
          .catch(_ => {});
      },
      //同意开通家庭
      consent(){
        var that=this;
         var data={
          id:this.dialogFalimyID,
          status:1
         }
         this.$confirm('确认开通？')
          .then(() => {
                let res=postFamilyAssessor(data);
                res.then((res)=>{
                     if(res.data.code==1){
           this.tableData=[]
                  var data={
              page:this.getInfo.page_code,
              list_num:this.getInfo.page_num
            }
            this.postFamilyList(data);
              this.$message({
              message: res.data.message,
              type: 'success'
            });
         }else{
             this.$message.error(res.data.message);
         }
                }).catch((err)=>{
                    console.log(err);
                })
                 that.dialogVisible=false;
          })
          .catch(_ => {});
        
      },
    /**-----------------网络请求生命周期函数--------------------- */
    //家庭审核接口
    async postFamilyAssessor(data){
      console.log(data)
         let res=await postFamilyAssessor(data);
         console.log(res);
         if(res.data.code==1){
           this.tableData=[]
                  var data={
              page:this.getInfo.page_code,
              list_num:this.getInfo.page_num
            }
            this.postFamilyList(data);
              this.$message({
              message: res.data.message,
              type: 'success'
            });
         }else{
             this.$message.error(res.data.message);
         }
    },
    //获取家庭列表网络请求
    async postFamilyList(data){
        let res=await postFamilyList(data);
        if(res.data.code==1){
          this.exp.num=res.data.data.total;
          this.tableData=this.tableData.concat(res.data.data.data);
              console.log(res.data)
        }else{
            this.$message.error(res.data.message);
        } 
    }
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
     var data={
      page:this.getInfo.page_code,
      list_num:this.getInfo.page_num
    }
    this.postFamilyList(data);
  },
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>

<style scoped>
.el-table .cell .el-button{
  margin: 5px;
}
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
</style>